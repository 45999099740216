import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import SectionTitle from "./section-title";
import ContactOptions from "./contact-options";
import ContactForm from "./contact-form";

const Section = styled.section`
  width: 100%;
  padding: 0 !important;
  grid-template-columns: 1fr 1fr;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 0 15% !important;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 0 5% !important;
  }
`;

const Container = styled.div`
  padding: 0 1rem;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: 0;
  }
`;

const Title = styled.h3`
  font-size: 1.125rem;
  font-weight: inherit;
  padding-left: calc(0.75rem + 4px);
  margin: 1.625rem 0;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding-left: 0;
  }
`;

const Grid = styled.div`
  grid-template-columns: 1fr 1fr;
  gap: 4.5rem;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    display: grid;
  }
`;

const Contact = () => {
  const contactOptionsTitle = useRef(null);
  const contactFormTitle = useRef(null);

  useEffect(() => {
    gsap.set([contactOptionsTitle.current, contactFormTitle.current], {
      opacity: 0,
    });

    ScrollTrigger.batch(
      [contactOptionsTitle.current, contactFormTitle.current],
      {
        once: true,
        start: "top 80%",
        onEnter: targets =>
          gsap.fromTo(
            targets,
            {
              opacity: 0,
              x: -100,
            },
            {
              opacity: 1,
              x: 0,
              stagger: 0.5,
              duration: 1,
            }
          ),
      }
    );
  }, []);

  return (
    <Section id="kontakt">
      <Container>
        <SectionTitle title="Skontaktuj się z nami" />
      </Container>
      <Grid>
        <Container>
          <Title ref={contactOptionsTitle}>Dane kontaktowe</Title>
          <ContactOptions />
        </Container>
        <div>
          <Container>
            <Title ref={contactFormTitle}>Formularz kontaktowy</Title>
          </Container>
          <ContactForm />
        </div>
      </Grid>
    </Section>
  );
};

export default Contact;
