import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Formik, Form as FormikForm, Field } from "formik";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import * as Yup from "yup";

const Container = styled.div`
  width: 100%;
  padding: 2.5rem 1rem;
  background-color: #ffffff;
  height: 600px;
`;

const Form = styled(FormikForm)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const FormElement = styled.div`
  position: relative;
  min-height: 3.125rem;
  width: 100%;
  margin: 0.5rem 0;
  grid-column: ${props => `span ${props.columns}` || "span 1"};
`;

const Input = styled.input`
  display: block;
  border: 1px solid ${props => (props.error ? "#c10000" : "#9f9f9f")};
  padding: 1rem;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  outline: none;
  box-shadow: none;
`;

const Textarea = styled(Input)`
  font-family: inherit;
  resize: none;
`;

const Label = styled.label`
  position: absolute;
  top: ${props => {
    let topValue = props.forTextarea ? "1rem" : "50%";

    if (props.fieldHasContent) {
      topValue = "0";

      if (props.forTextarea) {
        topValue = "-0.6rem";
      }
    }

    return topValue;
  }};
  left: 1rem;
  transform: ${props => (props.forTextarea ? "none" : "translateY(-50%)")};
  color: ${props => (props.error ? "#c10000" : "#9f9f9f")};
  background-color: #ffffff;
  padding: 0 0.5rem;
  pointer-events: none;
  font-size: ${props => (props.fieldHasContent ? "0.875rem" : "inherit")};
  transition-property: top, font-size;
  transition-duration: 0.2s;
`;

const Button = styled.button`
  display: block;
  border: 1px solid ${props => props.theme.colors.primary};
  width: 100%;
  height: 100%;
  min-height: 3.125rem;
  font-size: 1rem;
  background-color: transparent;
  transition: background-color 0.2s ease;
  text-align: center;
  padding: 0;
  margin: 0;

  &:hover,
  &:focus {
    cursor: pointer;
    background-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #c10000;
  font-size: 0.8rem;
  position: absolute;
  bottom: -1.25rem;
  left: 1.5rem;
`;

const ContactForm = () => {
  const container = useRef(null);

  useEffect(() => {
    gsap.set(container.current, { opacity: 0 });

    ScrollTrigger.batch(container.current, {
      once: true,
      start: "top 80%",
      onEnter: targets =>
        gsap.fromTo(
          targets,
          {
            opacity: 0,
            scale: 0.5,
          },
          {
            opacity: 1,
            scale: 1,
            stagger: 0.3,
            duration: 1.5,
          }
        ),
    });
  }, []);

  const handleFocus = event => {
    const label = event.target.labels[0];

    label.style.top =
      event.target instanceof HTMLTextAreaElement ? "-0.6rem" : 0;
    label.style.fontSize = "0.875rem";
  };

  const handleBlur = event => {
    const label = event.target.labels[0];

    label.style.removeProperty("top");
    label.style.removeProperty("font-size");
  };

  const ContactSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Wprowadzono za mało znaków")
      .max(50, "Wprowadzono za dużo znaków")
      .required("To pole jest wymagane"),
    lastName: Yup.string()
      .min(2, "Wprowadzono za mało znaków")
      .max(50, "Wprowadzono za dużo znaków")
      .required("To pole jest wymagane"),
    email: Yup.string()
      .email("Nieprawidłowy adres email")
      .required("To pole jest wymagane"),
    content: Yup.string()
      .min(10, "Wprowadzono za mało znaków")
      .max(2000, "Wprowadzono za dużo znaków")
      .required("To pole jest wymagane"),
  });

  return (
    <Container ref={container}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          content: "",
        }}
        validationSchema={ContactSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            content: values.content,
          };

          fetch("https://gctechnik.pl/mail_backend/mail.php", {
            method: "POST",
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then(() => {
              alert("Wiadomość została pomyślnie wysłana");
              resetForm();
            })
            .catch(error => {
              alert(`Błąd wysyłania wiadomości: ${error}`);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, isSubmitting, errors, touched }) => (
          <Form>
            <FormElement>
              <Label
                htmlFor="firstName"
                fieldHasContent={!!values.firstName}
                error={!!errors.firstName && !!touched.firstName}
              >
                Imie
              </Label>
              <Field
                name="firstName"
                id="firstName"
                as={Input}
                onFocus={event => handleFocus(event)}
                onBlur={event => handleBlur(event)}
                error={!!errors.firstName && !!touched.firstName}
              />
              {errors.firstName && touched.firstName && (
                <ErrorMessage>{errors.firstName}</ErrorMessage>
              )}
            </FormElement>
            <FormElement>
              <Label
                htmlFor="lastName"
                fieldHasContent={!!values.lastName}
                error={!!errors.lastName && !!touched.lastName}
              >
                Nazwisko
              </Label>
              <Field
                name="lastName"
                id="lastName"
                as={Input}
                onFocus={event => handleFocus(event)}
                onBlur={event => handleBlur(event)}
                error={!!errors.lastName && !!touched.lastName}
              />
              {errors.lastName && touched.lastName && (
                <ErrorMessage>{errors.lastName}</ErrorMessage>
              )}
            </FormElement>
            <FormElement columns={2}>
              <Label
                htmlFor="email"
                fieldHasContent={!!values.email}
                error={!!errors.email && !!touched.email}
              >
                E-mail
              </Label>
              <Field
                name="email"
                id="email"
                as={Input}
                onFocus={event => handleFocus(event)}
                onBlur={event => handleBlur(event)}
                error={!!errors.email && !!touched.email}
              />
              {errors.email && touched.email && (
                <ErrorMessage>{errors.email}</ErrorMessage>
              )}
            </FormElement>
            <FormElement columns={2}>
              <Label
                htmlFor="content"
                forTextarea
                fieldHasContent={!!values.content}
                error={!!errors.content && !!touched.content}
              >
                Treść
              </Label>
              <Field name="content">
                {({ field }) => (
                  <Textarea
                    {...field}
                    as="textarea"
                    id="content"
                    rows={10}
                    onFocus={event => handleFocus(event)}
                    onBlur={event => handleBlur(event)}
                    error={!!errors.content && !!touched.content}
                  />
                )}
              </Field>
              {errors.content && touched.content && (
                <ErrorMessage>{errors.content}</ErrorMessage>
              )}
            </FormElement>
            <FormElement columns={2}>
              <Button disabled={isSubmitting}>Wyślij wiadomość</Button>
            </FormElement>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ContactForm;
