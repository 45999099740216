import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Title = styled.h2`
  font-size: 1.75rem;
  font-weight: inherit;
  border-left: 4px solid ${props => props.theme.colors.primary};
  padding-left: 0.75rem;
  margin: 3rem 0 1rem 0;

  span {
    color: ${props => props.theme.colors.primary};
  }
`;

const Subtitle = styled.p`
  padding-left: calc(0.75rem + 4px);
  margin-bottom: 1.75rem;
`;

const SectionTitle = ({ children, title }) => {
  const titleElement = useRef(null);
  const subtitleElement = useRef(null);

  useEffect(() => {
    const targets = [titleElement.current];

    if (subtitleElement.current) {
      targets.push(subtitleElement.current);
    }

    gsap.set(targets, { opacity: 0 });

    ScrollTrigger.batch(targets, {
      once: true,
      start: "top 80%",
      onEnter: targets =>
        gsap.fromTo(
          targets,
          {
            opacity: 0,
            x: -50,
          },
          {
            opacity: 1,
            x: 0,
            duration: 2,
            stagger: 0.7,
          }
        ),
    });
  }, []);

  return (
    <>
      <Title ref={titleElement} dangerouslySetInnerHTML={{ __html: title }} />
      {children && <Subtitle ref={subtitleElement}>{children}</Subtitle>}
    </>
  );
};

export default SectionTitle;
