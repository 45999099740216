import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import PhoneIcon from "../images/svg/phone";
import MailIcon from "../images/svg/mail";
import MarkIcon from "../images/svg/mark";
import { graphql, useStaticQuery } from "gatsby";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 150px;
  width: 100%;
  gap: 0.125rem;

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    height: 600px;
    grid-auto-rows: 50%;
  }
`;

const ContactOption = styled.a`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: ${props => `span ${props.columns}` || "span 1"};
  transition-property: color, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  svg {
    max-height: 25%;
    transition: fill 0.2s ease;
  }

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.primary};
    color: #ffffff;

    svg {
      fill: #ffffff;
    }
  }
`;

const ContactOptions = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            address {
              label
              value
              link
            }
            mail {
              label
              value
            }
            phone {
              label
              value
            }
          }
        }
      }
    }
  `);

  const container = useRef(null);

  useEffect(() => {
    gsap.set(container.current.children, { opacity: 0 });

    ScrollTrigger.batch(container.current.children, {
      once: true,
      start: "top 80%",
      onEnter: targets =>
        gsap.fromTo(
          targets,
          {
            opacity: 0,
            scale: 0.5,
          },
          {
            opacity: 1,
            scale: 1,
            stagger: 0.3,
            duration: 1.5,
          }
        ),
    });
  }, []);

  return (
    <Container ref={container}>
      <ContactOption
        href={`tel:${data.site.siteMetadata.contact.phone.value}`}
        aria-label={data.site.siteMetadata.contact.phone.label}
      >
        <PhoneIcon />
        <p>{data.site.siteMetadata.contact.phone.value}</p>
      </ContactOption>
      <ContactOption
        href={`mailto:${data.site.siteMetadata.contact.mail.value}`}
        aria-label={data.site.siteMetadata.contact.mail.label}
      >
        <MailIcon />
        <p>{data.site.siteMetadata.contact.mail.value}</p>
      </ContactOption>
      <ContactOption
        href={data.site.siteMetadata.contact.address.link}
        aria-label={data.site.siteMetadata.contact.address.label}
        rel="nofollow noopener noreferrer"
        target="_blank"
        columns={2}
      >
        <MarkIcon />
        <p>{data.site.siteMetadata.contact.address.value}</p>
      </ContactOption>
    </Container>
  );
};

export default ContactOptions;
